
@import "src/utils/variables.scss", "src/utils/functions.scss";





/*
	----- AdBlock -----
*/

svg.adblock path {

	&.a, &.c {
		fill: $red-d;
	}

	&.b, &.d {
		fill: $white-xd;
	}
}



/*
	----- Help -----
*/

svg.help {

	path.in {
		fill: $white-xd;
	}
	path.out-1 {
		border: 1px solid $silver-xd;
		fill: $blue-d;
	}
	path.out-2 {
		fill: $white-xd;
	}
}



/*
	----- Loading -----
*/

svg.loading {
	@include transform(scale(1.25));

	circle {
		fill: $silver-m;
	}
}



/*
	----- Loading Spinner -----
*/

svg.loading_spinner {

	circle {
		fill: none;
		stroke: $skyblue-m;
	}
}
