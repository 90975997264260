
@import "./variables.scss";





/*
	----- Browser Compatibility -----
*/

@mixin align-content($v) {
	/* -ms-flex-line-pack: $v; */
	-webkit-align-content: $v;
					align-content: $v;
}

@mixin align-items($v) {
		-webkit-box-align: $v;
			 -ms-flex-align: $v;
	-webkit-align-items: $v;
					align-items: $v;
}

@mixin align-self($v) {
	-webkit-align-self: $v;
					align-self: $v;
}

@mixin appearance($v) {
	-webkit-appearance: $v;
		 -moz-appearance: $v;
			-ms-appearance: $v;
					appearance: $v;
}

@mixin animation($v) {
	-webkit-animation: $v;
		 -moz-animation: $v;
			 -o-animation: $v;
					animation: $v;
}

@mixin animation-fill-mode($v) {
	-webkit-animation-fill-mode: $v;
		 -moz-animation-fill-mode: $v;
			 -o-animation-fill-mode: $v;
					animation-fill-mode: $v;
}

@mixin background-image-linear-gradient($v) {
	/* background-image: -webkit-gradient(linear, left top, left bottom, from($v)); */
	background-image: -webkit-linear-gradient($v);
	background-image:    -moz-linear-gradient($v);
	background-image:     -ms-linear-gradient($v);
	background-image:      -o-linear-gradient($v);
	background-image:         linear-gradient($v);
}

@mixin background-size($v) {
	-webkit-background-size: $v;
		 -moz-background-size: $v;
			 -o-background-size: $v;
					background-size: $v;
}

@mixin border-radius($v) {
	-webkit-border-radius: $v;
		 -moz-border-radius: $v;
					border-radius: $v;
}

@mixin box-orient($v) {
	-webkit-box-orient: $v;
		 -moz-box-orient: $v;
			-ms-box-orient: $v;
					box-orient: $v;
}

@mixin box-sizing($v) {
	-webkit-box-sizing: $v;
		 -moz-box-sizing: $v;
					box-sizing: $v;
}

@mixin box-shadow($v) {
	-webkit-box-shadow: $v;
		 -moz-box-shadow: $v;
					box-shadow: $v;
}

@mixin calc($k, $v) {
	#{$k}: -webkit-calc(#{$v});
	#{$k}:    -moz-calc(#{$v});
	#{$k}:         calc(#{$v});
}

@mixin display-box {
	display:  -webkit-box;
	display:     -moz-box;
}

@mixin display-flex {
	display:  -ms-flexbox;
	display: -webkit-flex;
	display:         flex;
}

@mixin display-inline-flex {
	display: -webkit-inline-flex;
	display:     -ms-inline-flex;
	display:      -x-inline-flex;
	display:         inline-flex;
}

@mixin flex($v) {
	-webkit-box-flex: $v;
		 -moz-box-flex: $v;
			-webkit-flex: $v;
				 -moz-flex: $v;
					-ms-flex: $v;
							flex: $v;
}

@mixin flex-direction($v) {
	// 		-webkit-box-orient: vertical;
	//  -webkit-box-direction: normal;

	-webkit-flex-direction: $v;
		 -moz-flex-direction: $v;
			-ms-flex-direction: $v;
					flex-direction: $v;
}

@mixin flex-grow($v) {
// 	-webkit-box-flex: 1;
// -ms-flex-positive: 1;

	-webkit-flex-grow: $v;
		 -moz-flex-grow: $v;
					flex-grow: $v;
}

@mixin flex-wrap($v) {
	-webkit-flex-wrap: $v;
		 -moz-flex-wrap: $v;
			-ms-flex-wrap: $v;
					flex-wrap: $v;
}

@mixin fullscreen($e) {
	/*
	#{$e}:-webkit-full-screen, #{$e}:-moz-full-screen, #{$e}:-ms-full-screen, #{$e}:fullscreen {
		@content;
	}
	*/

	#{$e}:-webkit-full-screen { @content; }
		 #{$e}:-moz-full-screen { @content; }
			#{$e}:-ms-full-screen { @content; }
					 #{$e}:fullscreen { @content; }
}

@mixin justify-content($v) {
	// -webkit-box-pack: $v;
	// 	 -ms-flex-pack: $v;
	-webkit-justify-content: $v;
		 -moz-justify-content: $v;
					justify-content: $v;
}

@mixin keyframes($n) {
	@-webkit-keyframes #{$n} { @content; }
		 @-moz-keyframes #{$n} { @content; }
			 @-o-keyframes #{$n} { @content; }
					@keyframes #{$n} { @content; }
}

@mixin line-clamp($v) {
	-webkit-line-clamp: $v;
					line-clamp: $v;
}

@mixin object-fit($v) {
	-o-object-fit: $v;
		 object-fit: $v;
}

@mixin object-position($v) {
	-o-object-position: $v;
		 object-position: $v;
}

@mixin outline($v) {
	-moz-outline: $v;
			 outline: $v;
}

@mixin overflow($x, $y) {
	overflow-x: $x;
	overflow-y: $y;
}



/*
	----- Screens -----
*/

@mixin screen-mobile {
	@media only screen and (max-width: $_mobile-max-width) { @content; }
}

@mixin screen-tablet {
	@media only screen and (min-width: $_mobile-max-width) and (max-width: $_desktop-max-width) { @content; }
}

@mixin screen-desktop {
	@media only screen and (min-width: $_desktop-max-width) { @content; }
}



/*
	----- Screen Ranges -----
*/

@mixin screen-min($min) {
	@media only screen and (min-width: #{$min}) { @content; }
}

@mixin screen-max($max) {
	@media only screen and (max-width: #{$max}) { @content; }
}

@mixin screen-between($min, $max) {
	@media only screen and (min-width: #{$min}) and (max-width: #{$max}) { @content; }
}



/*
	----- Scrollbar -----
*/

@mixin scrollbar($e) {
	#{$e}::-webkit-scrollbar {					// #{$e}::scrollbar
		@content;
	}
}

@mixin scrollbar-thumb($e) {
	#{$e}::-webkit-scrollbar-thumb {		// #{$e}::scrollbar-thumb
		@content;
	}
}

@mixin scrollbar-track($e) {
	#{$e}::-webkit-scrollbar-track {		// #{$e}::scrollbar-track
		@content;
	}
}



@mixin text-decoration($v) {
	-webkit-text-decoration: $v;
					text-decoration: $v;
}

@mixin text-overflow($v) {
	-o-text-overflow: $v;
		 text-overflow: $v;
}

@mixin transform($v) {
	-webkit-transform: $v;
		 -moz-transform: $v;
			-ms-transform: $v;
			 -o-transform: $v;
					transform: $v;
}

@mixin transform-origin($v) {
	-webkit-transform-origin: $v;
		 -moz-transform-origin: $v;
			-ms-transform-origin: $v;
			 -o-transform-origin: $v;
					transform-origin: $v;
}

@mixin transition($v) {
	-webkit-transition: $v;
		 -moz-transition: $v;
			 -o-transition: $v;
					transition: $v;
}

@mixin user-select($v) {
	-webkit-touch-callout: $v;
		-webkit-user-select: $v;
		 -khtml-user-select: $v;
			 -moz-user-select: $v;
				-ms-user-select: $v;
						user-select: $v;
}



/*
	----- Global Functions -----
*/

@mixin fix-override-issue {

	a {
		cursor: pointer;
		display: block;
		margin: 0px;
		padding: 0px;
		// pointer-events: none;

		&:hover {
			color: unset;
		}
	}

	a, form {
		@include text-decoration(none);
	}
}



@mixin filter-settings_STYLE-1 {

	/* ----- Horizontal Line ----- */

	hr.type-2 {
		background-color: $white-xd;
	}

	/* ----- Radio Button Selections ----- */

	table {

		tbody tr {
			color: $white-m;

			td radio-button {
				background-color: $black-d;
				border-color: $black-d;

				&::before {
					background-color: $white-m;
					border-color: $black-d;
				}

				&[selected] {
					background-color: $white-m;
				}
			}
		}
	}

	/*
		----- Title -----
	*/

	h1 {
		color: $white-m;
	}

	/*
		----- Tab Selections -----
	*/

	multiple-tabs, single-tabs {
		margin-bottom: 15px;

		@include scrollbar-thumb('&') {
			background-color: $black-d;
		}

		tab-container {

			@include screen-mobile {
				font-size: $paragraph-ml;
			}

			background-color: $black-d;
			border-color: $black-d;
			color: $white-m;
			font-size: $paragraph-xl;

			&[selected] {
				background-color: $white-m;
				border-color: $white-m;
				color: $black-d;
			}
		}
	}

	/*
		----- Availabilities -----
	*/

	div.availabilities {

		@include scrollbar-thumb('&') {
			background-color: $black-d;
		}
		
		a.av_block {
			background-color: $black-d;
			border-color: $black-d;

			&[_selected] {
				background-color: $white-m;
				border-color: $white-m;

				span {
					color: $black-d;
				}
			}

			img.icon {
				border-color: $black-d;
			}

			span {
				color: $white-m;
			}
		}
	}
}



@mixin image_loading_spinner($size) {
	&[loading_spinner] {
		background-image: url("/assets/loading.svg");
		background-position: center center;
		background-repeat: no-repeat;
		@include background-size($size);
	}
}
