
@import
	// ~

	// "../../../node_modules/react-notification-alert/dist/animate.css",

	"../../../node_modules/bootstrap/scss/bootstrap.scss",

	// "../../../node_modules/swiper/modules/navigation/navigation.scss",
	"../../../node_modules/swiper/modules/pagination.scss",
	"../../../node_modules/swiper/modules/scrollbar.scss",
	"../../../node_modules/swiper/swiper.scss",



	"src/utils/variables.scss", "src/utils/functions.scss"
;



/*
	------------------------
	Fix Overriding Issues
	------------------------
*/

*, *::before, *::after {
	@include box-sizing(unset);
}

@include fix-override-issue;

button, input {
	overflow: unset;
}
