
:root {

	/*
		----------
		Devices
		----------
	*/

	--mobile-max-width:   650px;
	--tablet-max-width:   800px;
	--desktop-max-width: 1080px;





	/*
		---------
		Colors
		---------
	*/

	--black-l:  rgb(58, 60, 60);
	--black-m:  rgb(35, 38, 38);
	--black-d:  rgb(25, 25, 25);
	--black-xd: rgb(15, 15, 15);

	--blue-m: rgb(5, 95, 213);
	--blue-d: rgb(25, 90, 185);

	--green-m: rgb(0, 130, 0);

	--grey-m: rgb(85, 85, 85);
	--grey-d: rgb(80, 80, 80);

	--orange-m: rgb(255, 135, 0);

	--pink-m: rgb(215, 5, 85);

	--red-l: rgb(245, 85, 85);
	--red-m: rgb(220, 55, 55);
	--red-d: rgb(205, 25, 30);

	--silver-xxx: rgb(100, 100, 100);

	--silver-xl: rgb(245, 245, 245);
	--silver-l:  rgb(190, 190, 190);
	--silver-m:  rgb(175, 180, 185);
	--silver-d:  rgb(155, 155, 155);
	--silver-xd: rgb(100, 100, 100);

	--skyblue-m: rgb(110, 170, 250);

	--white-m:  rgb(215, 215, 215);
	--white-d:  rgb(240, 240, 240);
	--white-xd: rgb(255, 255, 255);





	/*
		-------------
		Font Sizes
		-------------
	*/

	--header-l:   50px;
	--header-ml:  40px;
	--header-m:   35px;
	--header-ms:  30px;
	--header-msh: 25px;
	--header-xs:  20px;
	--header-xxs: 15px;

	--paragraph-xl:  25px;
	--paragraph-ml:  20px;
	--paragraph-mlh: 17.5px;
	--paragraph-m:   15px;
	--paragraph-msh: 12.5px;
	--paragraph-ms:  10px;

	--title-massive: 200px;





	/*
		--------
		Sizes
		--------
	*/

	--sizes-xl5: 500px;
	--sizes-xl4: 400px;
	--sizes-xl3: 300px;
	--sizes-xl1m: 150px;
	--sizes-xl1: 100px;
	--sizes-l1: 80px;
	--sizes-l: 75px;
	--sizes-m: 50px;
	--sizes-s: 25px;
	--sizes-s2: 20px;
	--sizes-s1: 15px;
	--sizes-xs1: 7.5px;
	--sizes-xs: 5px;



}
