
/*
	----------
	Devices
	----------
*/

$mobile-max-width:   var(--mobile-max-width);
$tablet-max-width:   var(--tablet-max-width);
$desktop-max-width: var(--desktop-max-width);

$_mobile-max-width:   650px;
$_tablet-max-width:   800px;
$_desktop-max-width: 1080px;





/*
	---------
	Colors
	---------
*/

$black-l:  var(--black-l);
$black-m:  var(--black-m);
$black-d:  var(--black-d);
$black-xd: var(--black-xd);

$blue-m: var(--blue-m);
$blue-d: var(--blue-d);

$green-m: var(--green-m);

$grey-m: var(--grey-m);
$grey-d: var(--grey-d);

$orange-m: var(--orange-m);

$pink-m: var(--pink-m);

$red-l: var(--red-l);
$red-m: var(--red-m);
$red-d: var(--red-d);

$silver-xxx: var(--silver-xxx);

$silver-xl: var(--silver-xl);
$silver-l:  var(--silver-l);
$silver-m:  var(--silver-m);
$silver-d:  var(--silver-d);
$silver-xd: var(--silver-xd);

$skyblue-m: var(--skyblue-m);

$white-m:  var(--white-m);
$white-d:  var(--white-d);
$white-xd: var(--white-xd);





/*
	-------------
	Font Sizes
	-------------
*/

$header-l:   var(--header-l);
$header-ml:  var(--header-ml);
$header-m:   var(--header-m);
$header-ms:  var(--header-ms);
$header-msh: var(--header-msh);
$header-xs:  var(--header-xs);
$header-xxs: var(--header-xxs);

$paragraph-xl:  var(--paragraph-xl);
$paragraph-ml:  var(--paragraph-ml);
$paragraph-mlh: var(--paragraph-mlh);
$paragraph-m:   var(--paragraph-m);
$paragraph-msh: var(--paragraph-msh);
$paragraph-ms:  var(--paragraph-ms);

$title-massive: var(--title-massive);





/*
	--------
	Sizes
	--------
*/

$sizes-xl5: var(--sizes-xl5);
$sizes-xl4: var(--sizes-xl4);
$sizes-xl3: var(--sizes-xl3);
$sizes-xl1m: var(--sizes-xl1m);
$sizes-xl1: var(--sizes-xl1);
$sizes-l1: var(--sizes-l1);
$sizes-l: var(--sizes-l);
$sizes-m: var(--sizes-m);
$sizes-s: var(--sizes-s);
$sizes-s2: var(--sizes-s2);
$sizes-s1: var(--sizes-s1);
$sizes-xs1: var(--sizes-xs1);
$sizes-xs: var(--sizes-xs);


